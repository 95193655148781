import React from 'react'
import Contentful from '../../util/contentful'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useState } from "react";
import Carousel from 'react-bootstrap/Carousel'
import './projects.css'

function ProjectModel(props) {
  const projectName = props.projectName
  const query = `
  {
    projectInfoCollection(where: {projectName: "${projectName}"}) {
      items {
        projectName
        websiteUrl
        gitlabLink
        whatIs
        whatIDid
        toolsUsed
        toolsIcon
        imagesCollection {
          items {
            url
          }
        }
      }
    }
  }
  `;

  const contentful = Contentful(query, 'projectInfoCollection')
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let whatIDid
  let whatIs
  let toolsUsed
  let images
  let links

  if (contentful !== 'Loading...') {
    images =
      <>
        {contentful[0].imagesCollection.items.length !== 0 &&
          <Carousel>
            {contentful[0].imagesCollection.items.map((image, key) =>
              <Carousel.Item key={key}>
                <img key={`image-${key}`} className="d-block w-100" src={image.url} alt="First slide"
                />
              </Carousel.Item>
            )}
          </Carousel>
        }
      </>

    links =
      <div className='links'>
        {contentful[0].gitlabLink !== null
          ? <><a href={contentful[0].gitlabLink}><span id="gitlab" title="gitlab" className="mdi mdi-gitlab mdi-48px"> </span></a></>
          : <span id="gitlab" className="mdi mdi-gitlab mdi-48px unavailable"></span>
        }
        {contentful[0].websiteUrl !== null
          ? <><a id="sitelink" href={contentful[0].websiteUrl}><span className="mdi mdi-access-point mdi-48px"></span></a></>
          : <span id="sitelink" className="mdi mdi-access-point mdi-48px unavailable"></span>
        }
      </div>

    whatIDid =
      <>
        {contentful[0].whatIDid !== null &&
          <>
            <h5>What I did</h5>
            <p>
              {contentful[0].whatIDid}
            </p>
          </>
        }
      </>

    whatIs =
      <>
        {contentful[0].whatIs !== null &&
          <>
            <h5>What is {projectName}</h5>
            <p>
              {contentful[0].whatIs}
            </p>
          </>
        }
      </>

    toolsUsed =
      <>
        {contentful[0].toolsUsed !== null &&
          <>
            <h5>Tools used</h5>
            {contentful[0].toolsUsed.map((tool, key) =>
              <span key={key} className={`mdi ` + contentful[0].toolsIcon[key]}> {tool} </span>
            )}
          </>
        }
      </>
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Expore More
      </Button>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{projectName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {images}
          {links}
          {whatIs}
          {whatIDid}
          {toolsUsed}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProjectModel