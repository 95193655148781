import React from 'react'
import './aboutme.css'
import Contentful from '../../util/contentful'
import Fade from 'react-reveal/Fade';

function header() {
  const query = `
  {
    aboutMeCollection {
      items {
        title
        description
      }
    }
  }
  `;
  const contentful = Contentful(query, 'aboutMeCollection')
  return (
    <div id='About Me' className="aboutMe">
      <div className='container'>
        {contentful !== 'Loading...' &&
          <>
            <Fade bottom>
              <h1>{contentful[0].title}</h1>
              <p>{contentful[0].description}</p>
            </Fade>

          </>
        }
      </div>
    </div>
  )
}

export default header