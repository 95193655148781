import React from 'react'
import Contentful from '../../util/contentful'
import Fade from 'react-reveal/Fade';
import './contactme.css'

function contactMe() {
  const query = `
  {
    contactmeCollection {
      items {
        title
        contactInformation
        icon
        iconColour
      }
    }
  }
  `;
  const contentful = Contentful(query, 'contactmeCollection')
  let iconItems
  let heading

  if (contentful !== 'Loading...') {
    heading = <h1>{contentful[0].title}</h1>
    iconItems = contentful[0].icon.map((icon, key) =>
      <p key={key}>
        <a key={`a-${key}`} className='contactIcon' href={contentful[0].contactInformation[key]}
        ><span key={`span-${key}`} style={{ color: contentful[0].iconColour[key] }} color={contentful[0].iconColour[key]} className={`mdi ${icon} mdi-48px`}> </span></a>
      </p>
    )
  }

  return (
    <div id='Contact Me' className="contactMe">
      <div className='container'>
          <Fade bottom>
            <>
              {heading}
              {iconItems}
            </>
          </Fade>
      </div>
    </div>
  )
}

export default contactMe