import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-scroll'
import './header.css'

function header(props) {
  return (
    <Navbar expand="lg"sticky="top" >
      <Navbar.Brand href="/">Chris Hurley</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {props.navOptions.map((option, key) =>
            <Link className='nav-link' role='button' key={key} hashSpy={true} to={option} spy={true} smooth={true} offset={-55}>{option}</Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default header