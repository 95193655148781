import { useState, useEffect } from "react";

export default function Contentful(query, collection) {

  const [content, setContent] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/ngh23o9ushwe/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer YTDBnfhYsHnG9xWiSYqee9uKdQcnt24hg02uARRnXbY",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setContent(data);
      });
  }, []);

  if (!content) {
    return "Loading...";
  }
  return content[collection].items
  // render the fetched Contentful data
}