
import React, { useRef, useEffect } from 'react'

const Canvas = props => {
  
  const canvasRef = useRef(null)
  var canvasWidth = window.innerWidth - 15;
  
  useEffect(() => {
    var ballAmount

    function getRndColor() {
      var r = (255 * Math.random()) | 0.0,
        g = (255 * Math.random()) | 0.0,
        b = (255 * Math.random()) | 0.0;
      return "rgba(" + r + "," + g + "," + b + "," + 0.5 + ")";
    }
    
    var mouse = {
      x: undefined,
      y: undefined
    };
    var maxRadius = 40.0;
    // var minRadius = 2;
    var colourArray = ["#2C3E50", "#E74C3C", "#ECF0F1", "#3498DB", "#2980B9"];
    
    window.addEventListener("mousemove", function (event) {
      mouse.x = event.x;
      mouse.y = event.y;
    });
    
    window.addEventListener("resize", function () {
      canvasWidth = window.innerWidth
      if (canvasRef.current !== null ) {
      canvasRef.current.width = canvasWidth
      }
      init();
    });
    
    function Circle(x, y, dx, dy, radius, colour) {
      this.x = x;
      this.y = y;
      this.dx = dx;
      this.dy = dy;
      this.radius = radius;
      this.minRadius = radius;
      this.colour = colourArray[Math.floor(Math.random() * colourArray.length)];
    
      this.draw = function () {
        c.beginPath();
        c.arc(this.x, this.y, this.radius, 0, Math.PI * 2.0, false);
        c.fillStyle = this.colour;
        c.fill();
      };
    
      this.update = function () {
        if (this.x + this.radius > canvas.width || this.x - this.radius < 0.0) {
          this.dx = -this.dx;
        }
        if (this.y + this.radius > canvas.height || this.y - this.radius < 0.0) {
          this.dy = -this.dy;
        }
        this.x += this.dx;
        this.y += this.dy;
    
        // Interactivity
        if (
          mouse.x - this.x < 50.0 &&
          mouse.x - this.x > -50.0 &&
          mouse.y - this.y < 50.0 &&
          mouse.y - this.y > -50.0
        ) {
          if (this.radius < maxRadius) {
            this.radius += 1.0;
          }
        } else if (this.radius > this.minRadius) {
          this.radius -= 1.0;
        }
    
        this.draw();
      };
    }
    
    var circleArray = [];
    function init() {
      if (canvas.width < 800) {
        ballAmount = canvas.width / 20.0
      }
      else {
        ballAmount = canvas.width / 10.0
      }
      circleArray = [];
      for (var i = 0; i < ballAmount; i++) {
        var radius = Math.random() * 6.0 + 1.0;
        var x = Math.random() * (canvas.width - radius * 2.0) + radius;
        var y = Math.random() * (canvas.width - radius * 2.0) + radius;
        var dx = (Math.random() - 0.5) * 2.0;
        var dy = (Math.random() - 0.5) * 2.0;
        var colour = getRndColor();
        circleArray.push(new Circle(x, y, dx, dy, radius, colour));
      }
    }
    
    function animate() {
      requestAnimationFrame(animate);
      c.clearRect(0.0, 0.0, canvas.width, canvas.height);
    
      for (var i = 0; i < circleArray.length; i++) {
        circleArray[i].update();
      }
    }
    const canvas = canvasRef.current
    const c = canvas.getContext('2d')
    let animationFrameId
    
    //Our draw came here
    const render = () => {
      animationFrameId = window.requestAnimationFrame(animate)
    }
    init();
    animate();
    render()
    
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [])
  
  return <canvas width={canvasWidth} height='400'id='testing' ref={canvasRef} {...props}/>
}

export default Canvas