import React from 'react'
import { useParams } from "react-router-dom";
import Contentful from '../../util/contentful'
import Header from '../../components/header/header'
import './projectsPage.css'
import { useState } from "react";
import Canvas from '../../components/banner/Canvas'
import Fade from 'react-reveal/Fade';

function projectsPage() {
  const [image, setImages] = useState(null);
  let { projectName } = useParams();
  let navOptions = []
  let divs;
  let lcProjectName = projectName.toLowerCase().replace(/ /g, '')
  const query = `
  {
    ${lcProjectName}ProjectCollection(order: sectionPlacement_ASC) {
      items {
        title
        sectionPlacement
        information
        link
        icon
        imagesCollection {
          items {
            url
          }
        }
      }
    }
  }
  `;
  const contentful = Contentful(query, `${lcProjectName}ProjectCollection`)

  function isEven(value) {
    if (value === 0) {
      return 'bannerProject'
    }
    else if (value % 2 === 0)
      return 'light';
    else
      return 'dark';
  }

  function isActive(url) {
    if (image === url) {
      return 'imageActive'
    }
    else {
      return ''
    }
  }

  if (contentful !== 'Loading...') {
    for (let index = 0; index < contentful.length; index++) {
      navOptions.push(contentful[index].title);
    }
    divs = contentful.map((section, key) =>
      <div key={key} id={section.title} className={isEven(key)}>
        {key === 0 &&
          <Canvas />
        }
        <Fade bottom>
          <div key={key} className='container'>
            <h1 key={key}>{section.title}</h1>
            {section.information &&
              <>
                {section.information.split("\n").map((i, key) => {
                  return <p key={key}>{i}</p>;
                })}
              </>
            }
            {section.imagesCollection.items.length === 1 &&
              <img className="d-block w-100" src={section.imagesCollection.items[0].url} alt="" />
            }
            {section.imagesCollection.items.length > 1 &&
              <>
                <div className="card-deck">
                  <div className="card">
                    <div className="main-img">
                      <img alt={section.title} src={image} id="current" />
                    </div>
                  </div>
                  <div className="card" id="card-gal">
                    <div className="imgs">
                      {section.imagesCollection.items.map((images, key) => {
                        key === 0 && image === null &&
                          setImages(images.url)
                        return <img key={key} className={isActive(images.url)} alt={contentful[0].title} src={images.url} onClick={() => setImages(images.url)} />
                      })}
                    </div>
                  </div>
                </div>
              </>
            }
            {section.link &&
              <div className='center'><a href={section.link}><span title="gitlab" className={`mdi ${section.icon} mdi-48px`}> </span></a></div>
            }
          </div>
        </Fade>

      </div>

    )
  }
  return (
    <>
      <Header navOptions={navOptions} />
      {divs}
    </>
  );
}

export default projectsPage