import React from 'react'
import Banner from '../../components/banner/banner'
import AboutMe from '../../components/aboutMe/aboutme'
import Skills from '../../components/skills/skills'
import Projects from '../../components/projects/projects'
import Header from '../../components/header/header'
import ContactMe from '../../components/contactMe/contactme'
import './home.css'

function home() {
  let navOptions = ['About Me', 'Skills', 'Projects', 'Contact Me']
  return (
    <>
      <Header navOptions={navOptions} />
      <Banner />
      <AboutMe />
      <Skills />
      <Projects />
      <ContactMe />
    </>
  )
}

export default home