import React from 'react'
import './banner.css'
import Contentful from '../../util/contentful'
import Canvas from './Canvas'
function header() {
  const query = `
  {
    bannerCollection {
      items {
        title
        description
      }
    }
  }
  `;
  const contentful = Contentful(query, 'bannerCollection')
  return (
    <div id='home' className="banner">
      <Canvas />
      <div className='bannerContents'>
        {contentful !== 'Loading...' &&
          <>
            <h1>{contentful[0].title}</h1>
            <p>{contentful[0].description}</p>
          </>
        }
      </div>
    </div>
  )
}

export default header