import React from 'react'
import Contentful from '../../util/contentful'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Fade from 'react-reveal/Fade';
import './skills.css'

function skills() {
  const query = `
  {
    skillsCollection {
      items {
        title
        type
        icon
      }
    }
  }
  `;
  const contentful = Contentful(query, 'skillsCollection')
  let cards

  if (contentful !== 'Loading...') {
    cards = contentful.map((skillType, key) =>
      <Card key={key} >
        <Card.Body key={key}>
          <Card.Title key={`title-${key}`}>{skillType.title}</Card.Title>
          <ul key={`ul-${key}`}>
            {contentful[key].type.map((skill, key2) =>
              <li key={`li-${key2}-${key}`}><span key={`span-${key2}-${key}`} className={`mdi ` + contentful[key].icon[key2]}></span> {skill}</li>
            )}
          </ul>
        </Card.Body>
      </Card>
    )
  }

  return (
    <div id='Skills' className="skills">
      <div className='container'>
        <Fade bottom>
          <h1>Skills</h1>
          <CardDeck>
            {cards}
          </CardDeck>
        </Fade>
      </div>
    </div>
  )
}

export default skills