import Home from './pages/home/home'
import ProjectPage from './pages/projectsPage/projectsPage'
import "./App.css";
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
    return (
    <>
    <Router>
        <Switch>
          <Route path="/projects/:projectName">
            <ProjectPage />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
    </>
  );
}

export default App;