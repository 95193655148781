import React from 'react'
import Contentful from '../../util/contentful'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Button from 'react-bootstrap/Button'
import ProjectModel from './projectModel'
import './projects.css'
import Fade from 'react-reveal/Fade';
import { useHistory } from "react-router-dom";

function projects() {
  const query = `
  {
    projectsCollection {
      items {
        projectName
        projectType
        mainImage {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        shortDescription
      }
    }
  }
  `;
  const history = useHistory();
  const contentful = Contentful(query, 'projectsCollection')
  let projectSections
  let projectTypes = { 'Recent Projects': [], 'University Projects': [], 'Placement Projects': [], 'Other Projects': [] }

  function handleClick(id) {
    history.push(`/projects/${id}`);
  }

  if (contentful !== 'Loading...') {
    for (let index = 0; index < contentful.length; index++) {
      const type = contentful[index];
      projectTypes[type.projectType].push(type)
    }
    projectSections = Object.keys(projectTypes).map((projectType, key) =>
      <div key={key}>
        <Fade bottom>
          <h3 key={`${projectType}-${key}`}>{projectType}</h3>
          <CardDeck key={key}>
            {projectTypes[projectType].map((project, key2) =>
              <Card key={`${project}-${key2}`} style={{ minWidth: '18rem' }}>
                {project.mainImage !== null &&
                  <Card.Img key={`${project}-img-${key2}`} variant="top" src={project.mainImage.url} />
                }
                <Card.Body key={`cardbdy-${key2}`}>
                  <Card.Title key={`cardtitle-${key2}`}>{project.projectName}</Card.Title>
                  <Card.Text key={`cardtext-${key2}`}>
                    {project.shortDescription}
                  </Card.Text>
                  {projectType === 'University Projects'
                    ? <Button key={`${key2}`} onClick={() => handleClick(project.projectName)} >Expore More</Button>
                    : <ProjectModel projectName={project.projectName} />
                  }
                </Card.Body>
              </Card>
            )}
          </CardDeck>
        </Fade>
      </div>
    )
  }

  return (
    <div id='Projects' className="projects">
      <div className='container'>
        <Fade bottom>
          <>
            <h1>Projects</h1>
            {projectSections}
          </>
        </Fade>
      </div>
    </div>
  )
}

export default projects